import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';

import AWSAppSyncClient from 'aws-appsync'
import { ApolloProvider } from 'react-apollo'
import { Rehydrated } from 'aws-appsync-react' // this needs to also be installed when working with React

const client = new AWSAppSyncClient({
  url: 'https://zkbjshojxfhspdboqwstzpc6jq.appsync-api.eu-west-1.amazonaws.com/graphql',
  region: 'eu-west-1',
  auth: {
    type: 'API_KEY',
    apiKey: 'da2-wtlqpfv2azfb7fpfhmrrrwrg3u',
    // jwtToken: async () => token, // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
  }
})

// const client = new ApolloClient({
//   uri: 'https://zkbjshojxfhspdboqwstzpc6jq.appsync-api.eu-west-1.amazonaws.com/graphql',
//   headers: {
//     'x-api-key': 'da2-6i5nen3u5rf2tovkmeiybouuoi'
//   }
// });

// const httpLink = new HttpLink({
//   uri: 'https://zkbjshojxfhspdboqwstzpc6jq.appsync-api.eu-west-1.amazonaws.com/graphql',
//   headers: {
//     'x-api-key': 'da2-6i5nen3u5rf2tovkmeiybouuoi'
//   }
// });

// const wsLink = new WebSocketLink({
//   uri: `wss://dgix4t32uvak7ix4pb6buvfr34.appsync-api.eu-west-1.amazonaws.com/graphql`,
//   options: {
//     reconnect: true
//   }
// });

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === 'OperationDefinition' &&
//       definition.operation === 'subscription'
//     );
//   },
//   wsLink,
//   httpLink,
// );



const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

export default class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Rehydrated>
          <ThemeProvider theme={theme}>
            <Router history={browserHistory}>
              <Routes />
            </Router>
          </ThemeProvider>
        </Rehydrated>
      </ApolloProvider>
    );
  }
}
