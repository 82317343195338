import palette from 'theme/palette';

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: true,
  legend: { display: false },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        ticks: {
          fontColor: palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: false,
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      }
    ]
  }
};
