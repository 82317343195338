import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
} from '@material-ui/core';

import { options } from './chart';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.cardBackground
  },
  chartContainer: {
    height: 100,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const SensorDataHistorical = props => {
  const { className, data, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <div className={classes.chartContainer}>
          <Line
            data={data}
            options={options}
          />
        </div>
      </CardContent>
    </Card>
  );
};

SensorDataHistorical.propTypes = {
  className: PropTypes.string
};

export default SensorDataHistorical;
