import React, {useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
} from '@material-ui/core';

import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { buildSubscription } from 'aws-appsync'

import {Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.cardBackground
  },
  imageContainer: {
    marginTop: '10px'
  },
  image: {
    width: '100%'
  }
}));

const GET_CAMERA_UPLOAD_LABELS = gql`
query GetCameraUploadLabels($beehiveName: String!, $since: String!) {
  getBeehiveCameraUploadInferences(beehiveName:$beehiveName, since:$since) {
    beehiveName
    timestamp
    sourceKey
    response
  }
}
`;

const NEW_CAMERA_UPLOAD_LABELS_SUBSCRIPTION = gql`
  subscription {
    newBeehiveCameraUploadInference(beehiveName:"beeiot_sandbox_dev_v11") {
      beehiveName
      timestamp
      sourceKey
      response
    }
  }
`;

const ImageWithBoundingBoxesV2 = ({ imageUrl, labels }) => {
  const classes = useStyles();
  return (
    <div style={{
      position:'relative'
    }}>
      <img src={imageUrl} className={classes.image} alt="" />
      {labels.map(l =>
        <div style={{
          position: 'absolute',
          border: '1px solid red',
          left: l.coord[0],
          top: l.coord[1],
          width: l.coord[2],
          height: l.coord[3]
        }}></div>)}
    </div>
  );
}

const BeehiveImage = props => {
  const { className, data: rawData, meta, ...rest } = props;

  const classes = useStyles();

  const imageSize = { width: 670, height: 446};

  useEffect(() => {
    meta.subscribeToMore(
      buildSubscription(NEW_CAMERA_UPLOAD_LABELS_SUBSCRIPTION, GET_CAMERA_UPLOAD_LABELS)
    )
  }, [meta])

  if (!rawData.length) {
    return null;
  }

  const data = rawData[rawData.length - 1];
  const imageUrl = `https://d2g5ugli22ogid.cloudfront.net/${data.sourceKey}`

  let response;

  try {
    response = JSON.parse(data.response);
  } catch {
    response = JSON.parse(atob(data.response));
  }

  const caption = [];

  const labels = response.Labels
    .filter(l => l.Instances.length)
    .reduce((acc, l) => {
      const labelName = l.Name;
      const confidence = l.Confidence;

      caption.push({
        labelName,
        confidence,
        count: l.Instances.length
      });

      l.Instances.forEach(i => {
        acc.push({
          coord: [  // x,y,width, height
            imageSize.width * i.BoundingBox.Left,
            imageSize.height * i.BoundingBox.Top,
            imageSize.width * i.BoundingBox.Width,
            imageSize.height * i.BoundingBox.Height],
          // label: `${labelName}`
        })
      });
      return acc;
    }, []);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <div className={classes.imageContainer}>
          <ImageWithBoundingBoxesV2
            imageUrl={imageUrl}
            labels={labels} />
        </div>
        <Typography variant="caption" display="block" gutterBottom>
          {caption.map(c => (
            <p key={c.labelName}>{c.labelName} count: {c.count} ({Math.round(c.confidence)}% confidence)</p>
          ))}
        </Typography>
      </CardContent>
    </Card>
  );
};

BeehiveImage.propTypes = {
  className: PropTypes.string
};

export default graphql(GET_CAMERA_UPLOAD_LABELS, {
  options: {
    fetchPolicy: 'cache-and-network',
    variables: {
      beehiveName: "beeiot_sandbox_dev_v11",
      since: (new Date(new Date().getTime() - (3 * 24 * 60 * 60 * 1000))).toISOString().substring(0, 19)
    }
  },
  props: props => ({
    data: props.data.getBeehiveCameraUploadInferences || [],
    meta: props.data
  })
})(BeehiveImage);
