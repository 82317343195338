import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, LinearProgress } from '@material-ui/core';
import SensorDataCurrent from './components/SensorDataCurrent';
import SensorDataHistorical from './components/SensorDataHistorical';
import palette from 'theme/palette';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import BeehiveImage from './components/BeehiveImge';
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { buildSubscription } from 'aws-appsync'

const GET_SENSOR_DATA = gql`
  query GetSensorData($beehiveName: String!, $since: String!) {
    getSensorData(beehiveName:$beehiveName, since:$since) {
      beehiveName
      datetime
      temperature
      humidity
      weight
    }
  }
`;

const NEW_SENSOR_DATA_SUBSCRIPTION = gql`
  subscription {
    newSensorData(beehiveName:"beeiot_sandbox_dev_v11") {
      beehiveName
      datetime
      temperature
      humidity
      weight
    }
  }
`;



const chartData = (dataset, key) => {
  return {
    labels: dataset.map(e => (new Date(e.datetime)).toLocaleTimeString('en-US', { timeStyle: 'short', hourCycle: 'h24' })),
    datasets: [
      {
        borderColor: palette.primary.dark,
        borderWidth: 1,
        pointRadius: 0,
        cubicInterpolationMode: 'monotone',
        data: dataset.map(e => e[key])
      }
    ]
  }
};

const lastHourDiff = (data, key) => {
  if (data.length < 60) {
    return 0;
  }
  const latest = data[data.length - 1][key];
  const toCompare = data[data.length - 59][key];
  return latest - toCompare;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    height: '100vh'
  },
  icon: {
    height: 32,
    width: 32
  },
}));

const Dashboard = ({ data: rawData, meta }) => {
  const classes = useStyles();

  const [data, setData] = useState(rawData);

  useEffect(() => {
    const copy = data.slice();
    const newData = rawData.slice();
    const allData = copy.concat(newData);
    const length = allData.length;
    const over = length > (60 * 24);
    setData(allData.slice(over ? (60 * 24 * -1) : 0));
  }, [rawData, data]);

  useEffect(() => {
    meta.subscribeToMore(
      buildSubscription(NEW_SENSOR_DATA_SUBSCRIPTION, GET_SENSOR_DATA)
    )
  }, [meta])

  if (data.length === 0) {
    return (
      <div className={classes.root}>
        <LinearProgress variant="query" color="secondary" />
      </div>
    );
  }

  const temperatureData = chartData(data, 'temperature');
  const humidityData = chartData(data, 'humidity');
  const weightData = chartData(data, 'weight');

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="flex-end"
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={6}
          xs={6}
        >
          <SensorDataHistorical
            data={temperatureData}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={2}
          xs={6}
        >
          <SensorDataCurrent
            icon={<AcUnitIcon className={classes.icon} />}
            title="Temperature"
            value={data[data.length - 1].temperature.toFixed(2)}
            unit="°C"
            differenceValue={lastHourDiff(data, 'temperature').toFixed(2)}
            differencePeriod="Last hour"
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify="flex-end"
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={6}
          xs={6}
        >
          <SensorDataHistorical
            data={humidityData}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={2}
          xs={6}
        >
          <SensorDataCurrent
            icon={<LocalDrinkIcon className={classes.icon} />}
            title="Humidity"
            unit="%"
            value={data[data.length - 1].humidity.toFixed(2)}
            differenceValue={lastHourDiff(data, 'humidity').toFixed(2)}
            differencePeriod="Last hour"
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify="flex-end"
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={6}
          xs={6}
        >
          <SensorDataHistorical
            data={weightData}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={2}
          xs={6}
        >
          <SensorDataCurrent
            icon={<FitnessCenterIcon className={classes.icon} />}
            title="Weight"
            unit="kg"
            value={data[data.length - 1].weight.toFixed(2)}
            differenceValue={lastHourDiff(data, 'weight').toFixed(2)}
            differencePeriod="Last hour"
          />
        </Grid>
      </Grid>
      <Grid
          container
          justify="flex-end"
          spacing={4}
        >
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={6}
          >
            <BeehiveImage />
          </Grid>
        </Grid>
    </div>
  );
};

export default graphql(GET_SENSOR_DATA, {
  options: {
    fetchPolicy: 'cache-and-network',
    variables: {
      beehiveName: "beeiot_sandbox_dev_v11",
      since: (new Date(new Date().getTime() - (3 * 24 * 60 * 60 * 1000))).toISOString().substring(0, 19)
    }
  },
  props: props => ({
    data: props.data.getSensorData || [],
    meta: props.data
  })
})(Dashboard);
