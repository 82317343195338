import React, {useRef, useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    background: theme.palette.cardBackground
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.info.main,
    height: 56,
    width: 56,
  },
  value: ({prevValue, value}) => ({
    color: value < prevValue ? theme.palette.error.dark : value > prevValue ? theme.palette.success.main : 'black'
  }),
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIconPositive: {
    color: theme.palette.success.main,
  },
  differenceIconNegative: {
    color: theme.palette.error.dark,
  },
  differenceValuePositive: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1)
  },
  differenceValueNegative: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const SensorDataCurrent = props => {
  const { className, icon, title, value, unit, differenceValue, differencePeriod, ...rest } = props;

  const prevValueRef = useRef();
  useEffect(() => {
    prevValueRef.current = value;
  });
  const prevValue = prevValueRef.current;

  const classes = useStyles({prevValue, value});

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              gutterBottom
              variant="body2"
            >
              {title}
            </Typography>
            <Typography
              className={classes.value}
              variant="h3">{value}{unit}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              {icon}
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.difference}>
          {differenceValue > 0 ? (
            <ArrowUpwardIcon className={classes.differenceIconPositive} />
          ) : (
            <ArrowDownwardIcon className={classes.differenceIconNegative} />
          )}
          <Typography
            className={differenceValue > 0 ? classes.differenceValuePositive : classes.differenceValueNegative}
            variant="body2"
          >
            {differenceValue}
          </Typography>
          <Typography
            className={classes.caption}
            variant="caption"
          >
            {differencePeriod}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

SensorDataCurrent.propTypes = {
  className: PropTypes.string
};

export default SensorDataCurrent;
